
export type FormData = {
  formType: "Inquiry" | "Complaint";
  name: string;
  contactType: "email" | "phone";
  contact: string;
  title: string;
  message: string;
};
const host = "https://api.himanshusales.com";
export async function callApi(formData: FormData) {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Api-Key": process.env.REACT_APP_API_KEY as string,
  };

  return await fetch(`${host}/${formData.formType.toLocaleLowerCase()}`, {
    method: "POST",
    credentials: "include",
    headers: headers,
    body: JSON.stringify({
      name: formData.name,
      contact: formData.contact,
      contactType: formData.contactType,
      title: formData.title,
      message: formData.message,
    }),
  });
}