import React, { useEffect, useState } from "react";
import { callApi } from "../../store/form-store";

const Forms: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [placeholderText, setPlaceholderText] = useState("Select contact type");
  const [formType, setFormType] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    contact: "",
    title: "",
    message: "",
  });
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    if (errors.name) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [errors.name]);
  const handleSelectChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "email") {
      setPlaceholderText("Enter your email");
    } else if (value === "tel") {
      setPlaceholderText("Enter your phone number");
    } else {
      setPlaceholderText("Select contact type");
    }
  };
  const handleErrors = () => {
    if (errors.name || errors.contact || errors.title || errors.message) {
      setShowError(true);
    }
    setShowError(false);
    setErrors({
      name: "",
      contact: "",
      title: "",
      message: "",
    });
  };
  const handleFormTypeChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "inquiry") {
      setFormType("Inquiry");
    } else {
      setFormType("Complaint");
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const newErrors = {
      name: name ? "" : "Name is required",
      contact: contact ? "" : "Contact is required",
      title: title ? "" : "Title is required",
      message: message ? "" : "Message is required",
    };
    if (selectedOption === "email" && contact && !contact.includes("@")) {
      newErrors.contact = "Invalid email address";
    }
    if (selectedOption === "tel" && contact && !contact.match(/^\d{10}$/)) {
      newErrors.contact = "Invalid phone number";
    }
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);
    if (!hasErrors) {
      // Submit the form
      await callApi({
        formType: formType as "Inquiry" | "Complaint",
        name,
        contactType: selectedOption as "email" | "phone",
        contact,
        title,
        message,
      });
      console.log(
        `Form submitted without any error with name:${name}, contact: ${contact}, title: ${title}, message: ${message}`
      );
    } else {
      setShowError(true);
      console.log(
        `Form not submitted due to error with name:${name}, contact: ${contact}, title: ${title}, message: ${message}`
      );
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius: 10,
          flex: "1 0 auto", // Each product will flex to take up about 400px
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: "10px",
          }}
        >
          <select
            required={true}
            style={{
              width: "51%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
            onChange={handleFormTypeChange}
          >
            <option value="">Select Type</option>
            <option value="inquiry">Inquiry</option>
            <option value="complaint">Complaint</option>
          </select>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              padding: "10px",
            }}
          >
            <input
              type="text"
              placeholder="Name"
              value={name}
              onClick={handleErrors}
              onChange={(e) => setName(e.target.value)}
              required={true}
              style={{
                width: "50%",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "10px",
                border: errors.name ? "1px solid red" : "1px solid #ccc",
              }}
            />
            {showError && errors.name && (
              <span style={{ color: "red", marginBottom: "10px" }}>
                {errors.name}
              </span>
            )}
            <div
              style={{
                display: "flex",
                width: "50%",
                marginLeft: "-20px",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <select
                required={true}
                style={{
                  width: "48%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
                onChange={handleSelectChange}
                onClick={handleErrors}
              >
                <option value="">Select contact type</option>
                <option value="email">Email</option>
                <option value="tel">Phone</option>
              </select>
              <input
                type="text"
                required={true}
                placeholder={placeholderText}
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                onClick={handleErrors}
                style={{
                  width: "100%",
                  marginLeft: "20px",
                  marginRight: "-20px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: errors.contact ? "1px solid red" : "1px solid #ccc",
                }}
              />
            </div>
            {showError && errors.contact && (
              <span style={{ color: "red", marginBottom: "10px" }}>
                {errors.contact}
              </span>
            )}
            <input
              type="text"
              required={true}
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onClick={handleErrors}
              style={{
                width: "50%",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "10px",
                border: errors.title ? "1px solid red" : "1px solid #ccc",
              }}
            />
            {showError && errors.title && (
              <span style={{ color: "red", marginBottom: "10px" }}>
                {errors.title}
              </span>
            )}
            <textarea
              placeholder="Message"
              required={true}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onClick={handleErrors}
              style={{
                width: "50%",
                height: "100px",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "10px",
                border: errors.message ? "1px solid red" : "1px solid #ccc",
              }}
            />
            {showError && errors.message && (
              <span style={{ color: "red", marginBottom: "10px" }}>
                {errors.message}
              </span>
            )}
            <button
              type="submit"
              style={{
                padding: "15px 30px", // Increase padding for a larger button
                backgroundColor: "#D9310D",
                color: "white",
                border: "none",
                cursor: "pointer",
                borderRadius: "5px",
                marginTop: "10px",
                fontSize: "20px", // Increase font size for larger text
                width: "180px", // Set a fixed width for the button
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Forms;
